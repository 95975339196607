import styled from "@emotion/styled";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import React from "react";
import isMOV from "../../../util/isMOV";
import isPDF from "../../../util/isPDF";
import VideoPlaceholder from "./VideoPlaceholder";
import PDFPlaceholder from "./PDFPlaceholder";

function ImageRow({ imageSize, image, onClick }) {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "300px",
  });
  return (
    <RowWrapper item xs={imageSize} key={image.img_url} ref={ref}>
      <ImageWrapper onClick={onClick}>
        {entry?.isIntersecting &&
          (isMOV(image.img_url) ? (
            <VideoPlaceholder src={image.img_url} />
          ) : isPDF(image.img_url) ? (
            <PDFPlaceholder image={image.img_url} />
          ) : (
            <Image src={image.img_url} alt={image.inputLabel} loading="lazy" />
          ))}
      </ImageWrapper>
      <Typography>{image.inputLabel}</Typography>
      <NotesText>{image.notes}</NotesText>
      <Link href={image.img_url}>{image.img_url}</Link>
      <Button onClick={onClick}>View</Button>
    </RowWrapper>
  );
}

export default ImageRow;

const RowWrapper = styled(Grid)`
  height: 64px;
  min-width: 100%;
  display: flex;
  flex-direction: row;

  gap: 1em;
  color: black;
  align-items: center;
`;

const NotesText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  max-width: 50ch;
  margin-right: auto;
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
  aspect-ratio: 1;
  background-color: black;
`;

const ImageWrapper = styled.div`
  height: 100%;
  aspect-ratio: 1;

  &:hover {
    cursor: pointer;
  }
`;

const Link = styled.a`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  max-width: 25ch;
`;
