import styled from "@emotion/styled";
import { Button, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import LoadingDots from "../../components/LoadingDots";

const getTimeSlots = async (coords, startDate, endDate) => {
  //Uses today where no date is specified
  const start = startDate ?? dayjs().format("YYYY-MM-DD");
  //Uses 3 days from the first date where no end date is specified
  const end =
    endDate ??
    dayjs(startDate, "YYYY-MM-DD").add(2, "days").format("YYYY-MM-DD"); //TODO: remove adding any days
  const { data } = await axios.get(
    `https://api-dev.momentumsolar.io/acuity/condensed_slots?latitude=${coords?.latitude}&longitude=${coords?.longitude}&start_date=${start}&end_date=${end}`
  );

  return [
    { date: dayjs().format(), am_appts: 0, pm_appts: 4 },
    { date: dayjs().add(1, "day").format(), am_appts: 2, pm_appts: 1 },
    { date: dayjs().add(2, "day").format(), am_appts: 0, pm_appts: 0 },
  ];
};

function Appointments({ coords, onSelectTime, startDate }) {
  const { data: days, isFetching } = useQuery({
    queryKey: ["get-schedule", coords, startDate],
    queryFn: () => getTimeSlots(coords, startDate.format("YYYY-MM-DD")),
    refetchInterval: 1 * 60 * 1000,
    enabled: !!coords,
  });

  return (
    <Wrapper>
      {isFetching ? (
        <LoadingDots />
      ) : (
        days?.map(({ date, am_appts, pm_appts }, i) => {
          return (
            <Day
              key={date}
              date={date}
              morningSlots={am_appts}
              afternoonSlots={pm_appts}
              onSelectTime={onSelectTime}
            />
          );
        })
      )}
    </Wrapper>
  );
}

const Day = ({ date, morningSlots, afternoonSlots, onSelectTime }) => {
  return (
    <DayWrapper>
      <Typography>{dayjs(date).format("ddd MMM DD, YYYY")}</Typography>
      <Button
        onClick={() => onSelectTime({ timeOfDay: "AM", date })}
        variant={"contained"}
        disabled={morningSlots < 1}
        sx={{ width: "100%" }}
      >
        {morningSlots > 0 ? (
          <Stack>
            <Typography>Morning Appointment</Typography>
            <Typography>({morningSlots} Available)</Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography>No Morning Appointents</Typography>
            <Typography>Available</Typography>
          </Stack>
        )}
      </Button>
      <Button
        variant={"contained"}
        disabled={afternoonSlots < 1}
        onClick={() => onSelectTime("PM")}
      >
        {afternoonSlots > 0 ? (
          <Stack>
            <Typography>Afternoon Appointment</Typography>
            <Typography>({afternoonSlots} Available)</Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography>No Afternoon Appointents</Typography>
            <Typography>Available</Typography>
          </Stack>
        )}
      </Button>
    </DayWrapper>
  );
};

export default Appointments;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 1em;
`;

const DayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
`;
