import styled from "@emotion/styled";
import { Add, Remove } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useRef } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
  <ControlsWrapper>
    <Button variant="outlined" size="small" onClick={() => zoomIn()}>
      <Add />
    </Button>
    <Button variant="outlined" size="small" onClick={() => zoomOut()}>
      <Remove />
    </Button>
    <Button variant="outlined" size="small" onClick={() => resetTransform()}>
      Reset
    </Button>
  </ControlsWrapper>
);

export default function ZoomableImage({ image }) {
  const transformComponentRef = useRef(null);

  const zoomToImage = () => {
    if (transformComponentRef.current) {
      const { zoomToElement } = transformComponentRef.current;
      zoomToElement("imgExample");
    }
  };

  return (
    <TransformWrapper
      initialScale={1}
      centerOnInit={true}
      ref={transformComponentRef}
    >
      {(utils) => (
        <>
          <Controls {...utils} />
          <TransformComponent>
            <ImageWrapper>
              <Image
                src={image.img_url}
                alt={`${image.formLabel} - ${image.inputLabel}`}
              />
            </ImageWrapper>
          </TransformComponent>
        </>
      )}
    </TransformWrapper>
  );
}

const ImageWrapper = styled.div`
  max-height: 80vh;
  min-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: relative;
`;

const Image = styled.img`
  object-fit: contain;
  min-width: 100%;
  width: 100%;
  height: 100%;
`;

const ControlsWrapper = styled.div`
  position: absolute;
  top: 5em;
  left: 0;
  z-index: 2000000;
  display: flex;
  flex-direction: column;
  padding: 6px;
  gap: 6px;

  & > button {
    background-color: white;
  }
`;
