import styled from "@emotion/styled";
import { Check, SearchOutlined, Toys } from "@mui/icons-material";
import {
  Button,
  Collapse,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

const getCustomers = async (searchTerm, searchType) => {
  const { data } = await axios.get(
    `https://api-dev.momentumsolar.io/acuity/customers?${
      searchType.searchParamKey
    }=${searchTerm.toUpperCase()}`
  );
  return data[0]; //TODO: will remove the [0]
};

const SEARCH_BY_OPTIONS = [
  {
    label: "Opportunity Number",
    value: "opportunity_number",
    placeholder: "OP-1234",
    regexp: new RegExp(/[^OP-\d]/g),
    searchParamKey: "oppnum", //The key used query this field in API get
  },
  {
    label: "Project Number",
    value: "project_number",
    placeholder: "MS123456",
    searchParamKey: "ms", //The key used query this field in API get
    regexp: new RegExp(/[^MS\d]/g),
  },
];

function FindCustomer({ setImportedAddress }) {
  const { setValue, reset } = useFormContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchType, setSearchType] = useState(SEARCH_BY_OPTIONS[0]);
  //   const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    data: customer,
    isLoading,
    fetchStatus,
    error,
    refetch: fetchCustomer,
  } = useQuery({
    queryKey: ["search-customers", searchTerm, searchType],
    queryFn: () => getCustomers(searchTerm, searchType),
    enabled: false,
  });

  const handleSelectCustomer = (newCustomer) => {
    reset(); //Clear old form data
    if (!newCustomer) return setImportedAddress();
    Object.keys(newCustomer).forEach((key) => {
      if (key === "address") return setImportedAddress(newCustomer.address);
      if (key === "full_name") {
        const nameArr = newCustomer.full_name.split(" ");
        setValue("first_name", nameArr.shift());
        setValue("last_name", nameArr.join(" "));
      }
      setValue(key, newCustomer[key]);
    });
  };
  const setProjectNumber = (e) => {
    e?.preventDefault();
    const newValue = e.target.value.toUpperCase();
    const formattedValue = newValue.replace(searchType.regexp, "");
    setSearchTerm(formattedValue);
  };

  const handleSearch = () => {
    fetchCustomer();
  };

  useEffect(() => {
    handleSelectCustomer(customer);
  }, [customer]);

  return (
    <div>
      <Wrapper>
        <FormControl>
          <InputLabel id="search-by-label">Search By</InputLabel>
          <Select
            value={searchType}
            onChange={(e) => {
              setSearchTerm("");
              setSearchType(e.target.value);
            }}
            label={"Search By"}
            labelId="search-by-label"
            placeholder="Search By label"
            autoWidth
          >
            {SEARCH_BY_OPTIONS.map((option) => {
              return (
                <MenuItem key={option.label} value={option}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <TextField
          variant="standard"
          placeholder={searchType.placeholder}
          label={`Search for Household by ` + searchType.label}
          helperText=""
          value={searchTerm}
          onChange={setProjectNumber}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          disabled={!searchTerm}
          onClick={handleSearch}
        >
          Find Customer
        </Button>
      </Wrapper>
      {isLoading && fetchStatus === "fetching" && (
        <MessageText>Finding Customer...</MessageText>
      )}
      {error && (
        <MessageText style={{ color: "red" }}>
          {error?.message ?? error}
        </MessageText>
      )}
      <Collapse in={!!customer && fetchStatus !== "fetching"}>
        <FoundCustomerWrapper>
          <Typography>Customer Found:</Typography>

          <Typography fontSize={14}>{customer?.full_name}</Typography>
          <Typography fontSize={14}>{customer?.address}</Typography>
        </FoundCustomerWrapper>
      </Collapse>
    </div>
  );
}

export default FindCustomer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;

  align-items: flex-end;
`;

const MessageText = styled(Typography)`
  color: grey;
  font-size: 14px;
`;

const FoundCustomerWrapper = styled.div`
  border: 1px solid lightgrey;
  font-size: 12px;
  padding: 1em;
  margin-top: 1em;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  align-items: center;
`;
