import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";

function AssessmentDetails({ assessmentDetails }) {
  if (!assessmentDetails) return null;

  const { household_name, iis_sitevisitappt, mts_evsubmittedon } =
    assessmentDetails;

  const assessor =
    assessmentDetails[
      "_mts_siteassessor_value@OData.Community.Display.V1.FormattedValue"
    ];
  return (
    <Wrapper>
      <Typography>Assessor:</Typography>
      <Typography>{assessor}</Typography>

      <Typography variant="caption">Submitted: </Typography>
      <Typography variant="caption">
        {dayjs(mts_evsubmittedon).format("MM/DD/YYYY")}
      </Typography>
    </Wrapper>
  );
}

export default AssessmentDetails;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 1em;
`;
