import styled from "@emotion/styled";

import React from "react";
import { useFormContext } from "react-hook-form";
import {
  FormTextField,
  FormAddressAutocomplete,
} from "../../components/HookForm";
import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";

function CustomerInfo({ importedAddress }) {
  const { watch, setValue } = useFormContext();

  return (
    <Wrapper>
      <Typography>Customer Information</Typography>
      <div>
        <FormTextField
          name={"first_name"}
          label="First Name"
          placeholder={"Johnny"}
          variant="outlined"
          required
        />
        <FormTextField
          name="last_name"
          label="Last Name"
          placeholder={"Solar"}
          variant="outlined"
          sx={{ marginLeft: "1em" }}
          required
        />
      </div>
      <FormTextField
        name="phone"
        label="Phone"
        placeholder={"555-555-5555"}
        variant="outlined"
        fullWidth
        required
        rules={{
          validate: (val) =>
            !!String(val).match(
              /^\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})$/
            ) ?? "Phone number is invalid",
        }}
      />
      <FormTextField
        name="email"
        label="Email"
        type="email"
        placeholder={"j.solar@gmail.com"}
        variant="outlined"
        fullWidth
        required
        rules={{
          validate: (val) =>
            !!String(val)
              .toLowerCase()
              .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              ) ?? "Email address is invalid",
        }}
      />
      <Typography>Engineering Visit</Typography>
      <FormTextField
        name="project_number"
        label="Project Number"
        placeholder={"MS00000"}
        variant="outlined"
        helperText={'Project Number begins with "MS"'}
        fullWidth
        required
        rules={{
          validate: (val) =>
            !!String(val)
              .toUpperCase()
              .match(/^MS-?\d+$/) ??
            "Project Number is invalid. Begins with 'MS' and contains only numbers",
        }}
      />

      <FormAddressAutocomplete
        importedAddress={importedAddress}
        name="address"
        label="Full Address"
        placeholder={"123 NW Sunnyside blv, South Plainfield, NJ. 12345"}
        variant="outlined"
        fullWidth
        required
      />
      <FormControlLabel
        labelPlacement="start"
        label="Has HOA"
        control={
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{ marginLeft: "1em" }}
          >
            <Typography variant="caption">No</Typography>
            <Switch
              value={watch("HOA")}
              onChange={(_, v) => {
                setValue("HOA", v);
              }}
            />
            <Typography variant="caption">Yes</Typography>
          </Stack>
        }
        sx={{ alignSelf: "flex-start" }}
      />
      <FormTextField
        name={"notes"}
        label="Notes"
        multiline
        maxRows={6}
        minRows={3}
      />
    </Wrapper>
  );
}

export default CustomerInfo;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
