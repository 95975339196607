import React, { useState } from "react";
import BackgroundGradient from "../../components/BackgroundGradient";
import Card from "../../components/Card";
import SelectTerritory from "./SelectTerritory";

import styled from "@emotion/styled";
import Calendar from "../../components/Calendar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Stack } from "@mui/material";
import dayjs from "dayjs";
import ScheduleVisitDialog from "./ScheduleVisitDialog";

function TerritoryScheduler() {
  const [territory, setTerritory] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [selectedDate, setSelectedDate] = useState(dayjs());

  return (
    <BackgroundGradient>
      <Card>
        <Wrapper>
          <Stack direction="row" gap="1em">
            <SelectTerritory
              territory={territory}
              setTerritory={setTerritory}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker value={selectedDate} onChange={setSelectedDate} />
            </LocalizationProvider>
          </Stack>
          <Calendar
            territory={territory}
            onSelectTime={setSelectedTimeSlot}
            startDate={selectedDate}
          />
          <ScheduleVisitDialog
            timeSlot={selectedTimeSlot}
            setTimeSlot={setSelectedTimeSlot}
            territory={territory}
          />
        </Wrapper>
      </Card>
    </BackgroundGradient>
  );
}

export default TerritoryScheduler;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-width: 720px;
`;
