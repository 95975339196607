import { Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function PDFView({ image }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "0 100px",
      }}
    >
      <div
        style={{
          width: "100%",
          overflow: "auto",
        }}
      >
        <Document
          file={image.img_url}
          onLoadSuccess={onDocumentLoadSuccess}
          onError={(e) => {
            console.error("Error loading PDF:", e);
          }}
          error={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                alignItems: "center",
              }}
            >
              <Typography>There was an error reading file preview</Typography>
              <a href={image.img_url} target="_blank" rel="noreferrer">
                <Button variant="outlined">Open File</Button>
              </a>
            </div>
          }
        >
          <Page
            onError={(e) => {
              console.error("Error loading PDF Page:", e);
            }}
            pageNumber={1}
            size="A4"
          />
        </Document>
      </div>
    </div>
  );
}

export default PDFView;
