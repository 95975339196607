import React from "react";
import { Check, ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import LoadingDots from "../LoadingDots";

export function SubmitSuccessful({ children }) {
  return (
    <>
      <AnimatedCheck />
      <Typography>Appointment successfully made</Typography>
    </>
  );
}

export function SubmitLoading() {
  return (
    <>
      <LoadingDots />
      <Typography sx={{ marginTop: "1em" }}>
        Scheduling appointment...
      </Typography>
    </>
  );
}

export function SubmitError() {
  return <AnimatedError />;
}

const AnimatedCheck = styled(Check)`
  color: #5cb85c;
  height: 100px;
  width: 100px;
  opacity: 0;
  transform: translateY(-20px);
  animation: slideIn 0.2s ease-in forwards;

  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;

const AnimatedError = styled(ErrorOutline)`
  color: #d32f2f;
  height: 100px;
  width: 100px;
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.4s ease-in forwards;

  @keyframes slideIn {
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;
