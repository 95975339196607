import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { getSpacingValues } from "./functions";

function HourMarkers({ timeRange }) {
  const { maxTime, minTime, nOfHours, height } = getSpacingValues(timeRange);
  if (!nOfHours) return null;
  const lines = new Array(nOfHours * 2).fill(null).map((_, i) => (
    <HourLine
      key={"hour-line-" + i}
      style={{
        height,
        borderTopStyle: i % 2 === 0 ? "solid" : "dashed",
      }}
    />
  ));

  return <MarkersWrapper>{lines}</MarkersWrapper>;
}

function HourLabels({ timeRange }) {
  const { height, nOfHours } = getSpacingValues(timeRange);
  if (!nOfHours) return null;
  const labels = new Array(nOfHours * 2).fill(null).map((_, i) => {
    const time = dayjs(timeRange.earliest).add(i / 2, "hour");
    return i % 2 === 1 ? (
      <HourLine key={i} style={{ height }} />
    ) : (
      <HourLabel
        key={i}
        style={{
          height,
        }}
      >
        <Typography variant="caption" className="time">
          {time.format("h:00 a")}
        </Typography>
      </HourLabel>
    );
  });
  return <LabelsWrapper>{labels}</LabelsWrapper>;
}

export { HourMarkers, HourLabels };

const MarkersWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const LabelsWrapper = styled.div`
  height: 100%;
`;

const HourLine = styled.div`
  width: 100%;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
  margin-top: -1px; //Needed to align buttons to top of line
`;

const HourLabel = styled.div`
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: -1px; //Needed to align buttons to top of line

  .time {
    position: absolute;
    background: white;
    padding-right: 6px;
    top: -50%;
  }
`;
