function parseImages(images) {
  console.log("object", images);
  if (!images || !images.length) return {};
  const result = images?.reduce((sorted, img, id) => {
    const image = {
      ...img,
      formLabel: camelToText(img.form_key),
      inputLabel: camelToText(img.input_key),
      id,
    };
    const date = img.created_on.split(" ")[0];

    if (!sorted[image.form_key])
      sorted[image.form_key] = {
        [date]: { assessor: image.assessor_name, images: [] },
      };

    if (!sorted[image.form_key][date]) {
      sorted[image.form_key] = {
        ...sorted[image.form_key],
        [date]: { assessor: image.assessor_name, images: [] },
      };
    }

    sorted[image.form_key][date].images.push(image);

    return sorted;
  }, {});
  return result;
}

export default parseImages;

const camelToText = (string) => {
  const result = string.replaceAll(/([A-Z])/g, " $1").replaceAll("_", " ");
  return result.charAt(0).toUpperCase() + result.slice(1);
};
