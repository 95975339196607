import React, { useEffect, useMemo, useState } from "react";
import BackgroundGradient from "../../components/BackgroundGradient";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Card from "../../components/Card";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ImageGallery from "./ImageGallery";
import { GridView, Toc } from "@mui/icons-material";
import LoadingDots from "../../components/LoadingDots";
import AssessmentDetails from "./AssessmentDetails";
import parseImages from "./util/parseImages";

const IMAGE_SIZES = [
  { label: "x small", value: 1 },
  { label: "small", value: 2 },
  { label: "medium", value: 3 },
  { label: "large", value: 5 },
  // { label: "full", value: 20 },
];

const sanitizeProjectNumber = (projectNumber) => {
  return "MS" + projectNumber?.replaceAll(/\D+/g, "");
};

const getEV = async (project_number) => {
  const { data } = await axios.get(
    `https://api-dev.momentumsolar.io/ev/v1/photos/` + project_number
  );
  // data.push({
  //   img_url:
  //     "https://siteassessmentv1.s3.us-east-1.amazonaws.com/972b175f-de6f-e511-8101-c4346bad42f8/exteriorGround/Front-of-House_1_1705427508.mov",
  //   notes: "",
  //   form_key: "exteriorGround",
  //   input_key: "frontOfHouse",
  //   email: "MBharrat@momentumsolar.com",
  //   assessor_name: "Michael Bharrat",
  //   created_on: "1/17/2024 12:51 PM",
  // });

  return data;
};

function EVViewer() {
  const { projectNumber } = useParams();
  const [imageSize, setImageSize] = useState(IMAGE_SIZES[1]);
  const [displayMode, setDisplayMode] = useState("grid"); //'grid' or 'lines'

  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    queryKey: ["search-ev", sanitizeProjectNumber(projectNumber)],
    queryFn: () => getEV(projectNumber),
    enabled: sanitizeProjectNumber(projectNumber)?.length > 2,
  });
  const parsedImages = useMemo(() => parseImages(data), [data]);

  useEffect(() => {
    if (data?.length)
      //Prefetches images
      data?.forEach((img) => (new Image().src = img.img_url));
  }, [data]);

  return (
    <BackgroundGradient>
      <Card
        style={{
          maxWidth: "1200px",
          width: "100%",
          margin: "2em",
          marginTop: "0px",
          padding: "2em",
        }}
      >
        <Stack gap={"1em"} style={{ width: "100%" }}>
          <Typography variant="h5">Engineering Visit</Typography>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
            gap="2em"
            style={{ width: "100%" }}
          >
            <TextField
              placeholder="MS12345"
              label="Project Number"
              value={projectNumber}
              onChange={(e) => navigate("/view-ev/" + e.target.value)}
              required
            />
          </Stack>
          {data && (
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
            >
              {/* <AssessmentDetails assessmentDetails={data} /> */}
              <div />
              <Stack direction={"row"} gap="8px">
                {displayMode === "grid" && (
                  <FormControl>
                    <InputLabel id={"image-size-input-label"}>
                      Image Size
                    </InputLabel>
                    <Select
                      value={imageSize}
                      label={"Image Size"}
                      labelId="image-size-input-label"
                      onChange={(e) => setImageSize(e.target.value)}
                      size="small"
                      sx={{ minWidth: "88px" }}
                    >
                      {IMAGE_SIZES.map((size) => (
                        <MenuItem value={size} key={size.label}>
                          {size.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <ToggleButtonGroup
                  value={displayMode}
                  exclusive
                  onChange={(e, v) => setDisplayMode(v)}
                  aria-label="display mode"
                  size="small"
                >
                  <ToggleButton key="grid" value="grid" aria-label="grid mode">
                    <GridView />
                  </ToggleButton>
                  <ToggleButton
                    key="lines"
                    value="lines"
                    aria-label="lines mode"
                  >
                    <Toc />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Stack>
          )}
          {!!data?.length && (
            <ImageGallery
              data={parsedImages}
              imageSize={imageSize.value}
              displayMode={displayMode}
            />
          )}
          {!isLoading && projectNumber && !data?.length && (
            <Typography>
              No images found with Project Number {projectNumber}
            </Typography>
          )}
          {isLoading && projectNumber && <LoadingDots />}
        </Stack>
      </Card>
    </BackgroundGradient>
  );
}

export default EVViewer;
