import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import React from "react";
import isMOV from "../../../util/isMOV";
import isPDF from "../../../util/isPDF";
import VideoPlaceholder from "./VideoPlaceholder";
import PDFPlaceholder from "./PDFPlaceholder";

function ImageSquare({ imageSize, image, onClick }) {
  const [ref, entry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: "300px",
  });

  return (
    <ImageWrapper
      item
      xs={imageSize}
      key={image.img_url}
      onClick={onClick}
      ref={ref}
    >
      <ImageLabel label={image.inputLabel} imageSize={imageSize} />
      {entry?.isIntersecting ? (
        isMOV(image.img_url) ? (
          <VideoPlaceholder src={image.img_url} />
        ) : isPDF(image.img_url) ? (
          <PDFPlaceholder image={image.img_url} />
        ) : (
          <Image src={image.img_url} alt={image.inputLabel} loading="lazy" />
        )
      ) : (
        <ImagePlaceholder />
      )}
      {image.notes && <NotesText>{image.notes}</NotesText>}
    </ImageWrapper>
  );
}

export default ImageSquare;
const ImageLabel = ({ label, imageSize }) => {
  return (
    <div style={{ position: "absolute", background: "rgba(0,0,0,0.4)" }}>
      <Typography
        sx={{
          color: "white",
          padding: "0.5rem",
          fontSize: imageSize <= 2 ? "14px" : "inherit",
        }}
      >
        {label.replace(" Photos", "")}
      </Typography>
    </div>
  );
};

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 100%;
  aspect-ratio: 1;
  background-color: black;
`;

const ImagePlaceholder = styled.div`
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  aspect-ratio: 1;
  background-color: black;
`;

const ImageWrapper = styled(Grid)`
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
`;

const NotesText = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px;
  padding-top: 0px;
  background-color: #f7f7f7;
  font-size: 12px;
`;
