import styled from "@emotion/styled";
import React from "react";

function VideoPlayer({ video }) {
  return (
    <Wrapper>
      <video
        style={{ margin: "auto" }}
        id="my-video"
        controls
        preload="auto"
        width="100%"
        height="700"
        data-setup="{}"
      >
        <source src={video.img_url} />
        <p>
          To view this video please enable JavaScript, and consider upgrading to
          a web browser that
          <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
          </a>
        </p>
      </video>
    </Wrapper>
  );
}

export default VideoPlayer;

const Wrapper = styled.div`
  max-height: 800px;
  width: 100%;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: start;
`;
