const GOOGLE_MAPS_API_KEY = "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

export default function loadGoogleMaps() {
  if (typeof window !== "undefined") {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }
  }
}
