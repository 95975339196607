import styled from "@emotion/styled";
import { ReactComponent as TransparentLogo } from "../assets/backgroundMSLogo.svg";
import Header from "../views/Header";
import MSLogo from "../assets/msLogo.png";
import { Typography } from "@mui/material";
import appData from "../../package.json";

const BackgroundGradient = ({
  children,
  containerStyle,
  color = "#cccccc",
}) => (
  <StyledBackground color={color}>
    <div className="page-wrapper">
      <Header />
      <div className="children-wrapper" style={containerStyle}>
        {children}
      </div>
    </div>
    <div className="background-logo-wrapper">
      <TransparentLogo className="background-logo" />
    </div>
    <div className="ms-logo">
      <img
        src={MSLogo}
        style={{ height: "100%" }}
        alt={"Momentum Solar Logo"}
      />
      <Typography variant="caption">v{appData?.version}</Typography>
    </div>
  </StyledBackground>
);

const StyledBackground = styled.div`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .background-logo-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: -100;

    background: -webkit-linear-gradient(${(props) => props.color}, #fff);
    background: -moz-linear-gradient(${(props) => props.color}, #fff);
    background: -ms-linear-gradient(${(props) => props.color}, #fff);
    background: -o-linear-gradient(${(props) => props.color}, #fff);
    background: linear-gradient(${(props) => props.color}, #fff);
  }

  .background-logo {
    min-width: 1036px;
    width: 90%;
    min-height: 933px;
    aspect-ratio: 0.9;
  }

  .page-wrapper {
    position: absolute;
    max-width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    top: 0;
  }

  .children-wrapper {
    display: flex;
    justify-content: center;
  }

  .ms-logo {
    height: 3em;
    position: absolute;
    top: 0;
    left: 0;
    padding: 1em;
    display: flex;
    gap: 8px;
    color: white;
  }
`;

export default BackgroundGradient;
