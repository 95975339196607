import { SearchOutlined } from "@mui/icons-material";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const getTerritories = async () => {
  return (
    await axios.get(
      "https://api-dev.momentumsolar.io/acuity/get_ev_territories"
    )
  ).data;
};

function SelectTerritory({ territory, setTerritory, style, ...props }) {
  const navigate = useNavigate();
  const { territory: territoryParam } = useParams();

  const { data: territories, isLoading } = useQuery({
    queryKey: ["get-territories"],
    queryFn: getTerritories,
    initialData: [],
  });

  useEffect(() => {
    const newTerritory = territories.find(
      (territory) =>
        territory?.acuity_appointment_id === territoryParam ||
        territory?.territory_name?.toLowerCase() ===
          territoryParam?.toLowerCase()
    );

    setTerritory(newTerritory);
  }, [territoryParam, territories]);

  return (
    <Autocomplete
      options={territories}
      value={territory}
      onChange={(_, newValue) => {
        navigate("/territories/" + (newValue?.acuity_appointment_id ?? ""));
      }}
      sx={{ width: 300, ...style }}
      getOptionLabel={(option) => option?.territory_name}
      isOptionEqualToValue={(option, value) =>
        option?.territory_name === value?.territory_name
      }
      renderInput={({ InputProps, ...props }) => {
        return (
          <TextField
            {...props}
            variant="standard"
            label="Territory"
            placeholder="Territory"
            helperText={isLoading && "Loading territories ..."}
            InputProps={{
              ...InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
          />
        );
      }}
      {...props}
    />
  );
}

export default SelectTerritory;
