import { useEffect, useRef, useState } from "react";
import loadGoogleMaps from "../loadGoogleMaps";

function useGetPlaceDetails(placeId, options = {}) {
  const placesService = useRef(null);
  const [data, setData] = useState();

  useEffect(() => {
    if (!window.google) return;
    (async () => {
      if (!placesService.current) {
        const { PlacesService } = await window.google?.maps?.importLibrary(
          "places"
        );

        if (PlacesService)
          placesService.current = new PlacesService(
            document.createElement("div")
          );
      }

      if (!placeId) return;

      placesService?.current?.getDetails({ placeId }, (placeDetails) => {
        if (!placeDetails?.geometry?.location) setData();
        const newData = {
          latitude: placeDetails?.geometry?.location.lat(),
          longitude: placeDetails?.geometry?.location.lng(),
        };
        setData(newData);
      });
    })();
  }, [placeId]);

  return data;
}

export default useGetPlaceDetails;
