import styled from "@emotion/styled";
import { CatchingPokemon, ChevronLeft } from "@mui/icons-material";
import { Button, Collapse, Grid, Stack, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import GalleryDialog from "./GalleryDialog";
import ImageSquare from "./ImageSquare";
import ImageRow from "./ImageRow";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { datePickerToolbarClasses } from "@mui/x-date-pickers";

function ImageSection({
  label,
  data = {},
  imageSize = 1,
  displayMode = "grid",
}) {
  const [sectionOpen, setSectionOpen] = useState(false);
  const [galleryViewIndex, setGalleryViewIndex] = useState();

  const allImages = useMemo(() => {
    let imgs = [];
    Object.keys(data).map((key) => {
      imgs = [...imgs, ...data[key].images];
    });
    return imgs;
  }, []);

  const handleClick = (image) => {
    setGalleryViewIndex(allImages.indexOf(image));
  };

  return (
    <Wrapper>
      <GalleryDialog
        index={galleryViewIndex}
        setIndex={setGalleryViewIndex}
        images={allImages}
      />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ padding: "0em 1em" }}
      >
        <Typography variant="h6">{label}</Typography>
        <CollapseButton
          onClick={() => setSectionOpen((prev) => !prev)}
          size="small"
        >
          {<Chevron sx={{ rotate: sectionOpen ? "90deg" : "-90deg" }} />}
        </CollapseButton>
      </Stack>
      <Collapse in={sectionOpen}>
        {displayMode === "grid" ? (
          <Grid
            container
            spacing={imageSize}
            key={`${displayMode}_${label}`}
            sx={{ paddingBottom: "2em" }}
          >
            {Object.keys(data).map((date, i) => {
              if (!data[date]) return null;
              const { assessor, images } = data[date];

              const section = images?.map((image, i) => (
                <ImageSquare
                  key={image.img_url + displayMode + i}
                  image={image}
                  imageSize={imageSize}
                  onClick={() => {
                    handleClick(image);
                  }}
                />
              ));
              return (
                <>
                  <UploadDetails>
                    <Line />
                    <Typography>
                      {date} - {assessor}
                    </Typography>
                    <Line />
                  </UploadDetails>
                  {section}
                </>
              );
            })}
          </Grid>
        ) : (
          <Stack key={`${displayMode}_${label}`}>
            {Object.keys(data).map((date, i) => {
              if (!data[date]) return null;
              const { assessor, images } = data[date];

              const section = images?.map((image, i) => (
                <ImageRow
                  key={image.img_url + displayMode + i}
                  image={image}
                  imageSize={imageSize}
                  onClick={() => {
                    handleClick(image);
                  }}
                />
              ));
              return (
                <>
                  <UploadDetails>
                    <Line />
                    <Typography>
                      {date} - {assessor}
                    </Typography>
                    <Line />
                  </UploadDetails>
                  {section}
                </>
              );
            })}
          </Stack>
        )}
      </Collapse>
    </Wrapper>
  );
}

export default ImageSection;

const Wrapper = styled.div`
  border-bottom: 1px solid lightgrey;
`;

const Chevron = styled(ChevronLeft)`
  transition: 0.4;
  height: 64px;
  width: 64px;
`;

const CollapseButton = styled(Button)`
  padding: 0px;
`;

const UploadDetails = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const Line = styled.div`
  flex: 2;
  width: 100%;
  min-height: 1px;
  background-color: lightgrey;
`;
