import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { DayLabel, DaySchedule } from "./Day";
import { HourLabels, HourMarkers } from "./HourMarkers";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import axios from "axios";
import LoadingDots from "../LoadingDots";

const getTimeSlots = async (territory, startDate, endDate) => {
  //Uses today where no date is specified
  const start = startDate ?? dayjs().format("YYYY-MM-DD");
  //Uses 3 days from the first date where no end date is specified
  const end =
    endDate ??
    dayjs(startDate, "YYYY-MM-DD").add(2, "days").format("YYYY-MM-DD");

  const { data } = await axios.get(
    `https://api-dev.momentumsolar.io/acuity/get_slots?appointment_id=${territory.acuity_appointment_id}&start_date=${start}&end_date=${end}`
  );

  return Object.keys(data).map((key) => ({
    date: key,
    openTimeSlots: data[key],
  }));
};

function Calendar({
  startDate,
  territory,
  onSelectTime = (time) =>
    console.warn("Missing onSelectTime fn for selected time: " + time),
}) {
  const {
    data: days,
    dataUpdatedAt,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["get-schedule", territory, startDate],
    queryFn: () => getTimeSlots(territory, startDate.format("YYYY-MM-DD")),
    refetchInterval: 1 * 60 * 1000,
    enabled: !!territory,
  });

  const timeRange = getTimeRange(days);
  return (
    <CalendarWrapper>
      {/* {territory && (
        <Typography variant="h6">{territory?.territory_name}</Typography>
      )} */}

      {isLoading && isFetching && <LoadingDots style={{ marginTop: "2em" }} />}
      {days && (
        <DaysWrapper>
          <SyncTimeText variant="caption">
            Last synced at {dayjs(dataUpdatedAt).format("h:mm")}{" "}
          </SyncTimeText>
          {days?.map(({ date }, i) => (
            <DayLabel key={date} date={date} />
          ))}

          <HourLabels timeRange={timeRange} />

          <TimeSlotsWrapper>
            <HourMarkers timeRange={timeRange} />
            {days?.map(({ openTimeSlots }, i) => (
              <DaySchedule
                key={i}
                openTimeSlots={openTimeSlots}
                timeRange={timeRange}
                onSelectTime={onSelectTime}
              />
            ))}
          </TimeSlotsWrapper>
        </DaysWrapper>
      )}
    </CalendarWrapper>
  );
}

const getTimeRange = (data) => {
  const range = { earliest: null, latest: null };
  if (!data) return;
  const allTimes = data.map((day) => day.openTimeSlots).flat();

  return allTimes?.reduce((range, { time }) => {
    const hour = dayjs(time).hour();
    const earliest = dayjs(range?.earliest).hour();
    const latest = dayjs(range?.latest).hour();

    if (!range.earliest || hour < earliest) range.earliest = time;
    if (!range.latest || hour > latest) range.latest = time;

    return range;
  }, range);
};

export default Calendar;

const CalendarWrapper = styled.div``;

const DaysWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 64px 1fr 1fr 1fr;
  grid-template-rows: auto 300px;

  min-width: 720px;
`;

const TimeSlotsWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;

  grid-column: 2 / span 3;
  grid-template-columns: 1fr 1fr 1fr;
`;

const SyncTimeText = styled(Typography)`
  font-size: 10px;
  line-height: 1.2;
  align-self: center;
`;
