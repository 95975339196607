import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useFormContext } from "react-hook-form";
import CustomerInfo from "./CustomerInfo";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";
const advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const DUMMY_OLD_APPT = "2023-10-15T12:26:57-07:00";

function ConfirmationDialog({ selectedTimeSlot, setSelectedTimeSlot }) {
  const {
    watch,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useFormContext();

  const onSubmit = (data) => {
    const body = {
      ...data,
      appointment_time: selectedTimeSlot,
    };
    console.log(body);
  };

  const { data: oldAppointment } = useQuery({
    queryKey: ["get-old-appointment", watch("phone")],
    queryFn: () => {
      return DUMMY_OLD_APPT;
    },
  });
  return (
    <Dialog open={!!selectedTimeSlot} onClose={() => setSelectedTimeSlot()}>
      <DialogTitle>
        <span style={{ fontSize: 24 }}>Confirm Booking</span>
        <Line />
        {selectedTimeSlot?.timeOfDay === "AM"
          ? "Morning of"
          : "Afternoon of"}{" "}
        {dayjs(selectedTimeSlot?.date).format("MMM Do, YYYY")}
      </DialogTitle>
      <DialogContent>
        <Stack gap="1rem">
          <CustomerSummary {...getValues()} />
          {oldAppointment && (
            <OldAppointment
              appointmentTime={oldAppointment}
              customerName={watch("full_name")}
            />
          )}
          <Button
            variant="contained"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={!!errors.length}
          >
            Book Appointment
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setSelectedTimeSlot()}
          >
            Back
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmationDialog;

const CustomerSummary = ({
  full_name,
  opportunity_number,
  project_number,
  address,
  phone,
  email,
}) => {
  return (
    <div style={{ margin: "auto" }}>
      <Typography variant="h6">Appointment Details</Typography>
      <Typography>{full_name}</Typography>
      <Typography>{address}</Typography>
      <Typography>{phone}</Typography>
      <Typography>{email}</Typography>
      {opportunity_number && (
        <Typography>Opportunity Number: {opportunity_number}</Typography>
      )}
      {project_number && (
        <Typography>Project Number: {project_number}</Typography>
      )}
    </div>
  );
};

const OldAppointment = ({ appointmentTime, customerName }) => {
  console.log(appointmentTime);
  return (
    <OldAppointmentWrapper>
      <Typography>
        {customerName} already has an Engineering Visit scheduled for
      </Typography>
      <Typography variant="h6">
        {dayjs(appointmentTime).format("dddd MMM Do, YYYY")}
      </Typography>
      <Typography>
        If you book this new appointment, the old one will be canceled
        automatically
      </Typography>
    </OldAppointmentWrapper>
  );
};

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #336699;
`;

const OldAppointmentWrapper = styled.div`
  border: 1px solid lightgrey;
  border-radius: 6px;
  padding: 16px;
  flex-direction: column;
  display: flex;
  align-items: center;
  gap: 0.5em;
  text-align: center;
`;
