import { Route, Routes, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./views/Auth/PrivateRoute";

import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import PageNotFound from "./views/Auth/PageNotFound";
import NotAuthorized from "./views/Auth/NotAuthorized";
import TerritoryScheduler from "./views/TerritoryScheduler";
import Scheduler from "./views/Scheduler";
import loadGoogleMaps from "./util/loadGoogleMaps";
import AvailableAppointments from "./views/AvailableAppointments";
import EVViewer from "./views/EVViewer";
import { pdfjs } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const queryClient = new QueryClient();

loadGoogleMaps();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <Routes>
            {/* <Route path="/" element={<PrivateRoute />}>
              <Route>hi</Route>
            </Route> */}
            <Route path="/not-authorized" element={<NotAuthorized />} />
            {/* <Route path="/territories/" element={<TerritoryScheduler />} />
          <Route
            path="/territories/:territory"
            element={<TerritoryScheduler />}
          /> */}
            <Route path="/view-ev" element={<EVViewer />} />
            <Route path="/view-ev/:projectNumber" element={<EVViewer />} />
            <Route
              path="/available-appointments"
              element={<AvailableAppointments />}
            />
            <Route
              path="/available-appointments/:state"
              element={<AvailableAppointments />}
            />
            <Route path="/scheduler" element={<Scheduler />} />
            {/* <Route path="/scheduler/:project_number" element={<Scheduler />} /> */}
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </MsalAuthenticationTemplate>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
