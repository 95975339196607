import { Button } from "@mui/material";
import { ReactComponent as LogoutIcon } from "../../assets/logoutIcon.svg";

import { useMsal } from "@azure/msal-react";
import styled from "@emotion/styled";

function Header() {
  const { instance } = useMsal();
  return (
    <StyledHeader>
      <div className="logout-wrapper">
        <p style={{ color: "white", fontSize: "12px", fontWeight: "bold" }}>
          Signed in as {instance.getActiveAccount().name}
        </p>
        <Button
          onClick={() =>
            instance.logoutRedirect({ postLogoutRedirectUri: "/" })
          }
          variant="contained"
          sx={{
            color: "grey",
            zIndex: 10000,
            right: 0,
            margin: "1em",
            background: "white",
          }}
          endIcon={<LogoutIcon />}
        >
          log out
        </Button>
      </div>
    </StyledHeader>
  );
}

export default Header;

const StyledHeader = styled.div`
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  img {
    height: 3em;
    margin-left: 0.5em;
  }

  .logout-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;
