import React, { useState } from "react";
import BackgroundGradient from "../../components/BackgroundGradient";
import Card from "../../components/Card";

import styled from "@emotion/styled";
import { Collapse, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import CustomerInfo from "./CustomerInfo";
import { FormAddressAutocomplete } from "../../components/HookForm";
import useGetPlaceDetails from "../../util/Hooks/useGetPlaceDetails";
import Appointments from "./Appointments";
import FindCustomer from "./FindCustomer";
import ConfirmationDialog from "./ConfirmationDialog";
// import findTerritoryFromCoords from "../../util/findTerritoryFromCoords";

function Scheduler() {
  const [importedAddress, setImportedAddress] = useState();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();
  const [selectedDate, setSelectedDate] = useState(dayjs().add(5, "day"));
  const [placeId, setPlaceId] = useState();
  const coords = useGetPlaceDetails(placeId);

  const form = useForm();
  const { watch } = form;

  return (
    <BackgroundGradient>
      <Card>
        <FormProvider {...form}>
          <Wrapper>
            <StepHeader step={1} text={"Find Household by Project Number"} />
            <FindCustomer setImportedAddress={setImportedAddress} />
            <StepHeader
              step={2}
              text={"Verify Address"}
              active={!!watch("project_number")}
            />
            <Collapse in={!!watch("project_number")}>
              <FormAddressAutocomplete
                importedAddress={importedAddress}
                name="address"
                label="Full Address"
                placeholder={
                  "123 NW Sunnyside blv, South Plainfield, NJ. 12345"
                }
                variant="outlined"
                fullWidth
                required
                setPlaceId={setPlaceId}
              />
            </Collapse>
            <StepHeader
              step={3}
              text={"Select Appointment"}
              active={!!coords && !!watch("address")}
            />
            <Collapse in={!!coords && !!watch("address")}>
              <Appointments
                coords={coords}
                onSelectTime={setSelectedTimeSlot}
                startDate={selectedDate}
              />
            </Collapse>
            <ConfirmationDialog
              setSelectedTimeSlot={setSelectedTimeSlot}
              selectedTimeSlot={selectedTimeSlot}
            />
          </Wrapper>
        </FormProvider>
      </Card>
    </BackgroundGradient>
  );
}

const StepHeader = ({ step, text, active = true }) => {
  return (
    <Stack direction={"row"} gap={"1em"} alignItems={"center"}>
      <Number style={{ backgroundColor: active ? "#336699" : "grey" }}>
        <Typography>{step}</Typography>
      </Number>
      <Typography variant="h5">{text}</Typography>
    </Stack>
  );
};

export default Scheduler;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  min-width: 100%;
`;

const Number = styled(Typography)`
  color: white;
  font-weight: bold;
  background-color: #336699;
  aspect-ratio: 1;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
`;
