import styled from "@emotion/styled";
import { Paper as MUICard } from "@mui/material";

const Card = styled(MUICard)`
  display: flex;
  background: white;
  padding: clamp(1em, 8vw, 4em);
  margin: clamp(2em, 16vw, 4em) 2em;
  min-width: 640px;
  box-shadow: 1px 1px 3px #00000044;
`;

export default Card;
