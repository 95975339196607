import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent as DialogContentBase,
  DialogTitle,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SearchCustomersByName } from "../../components/ScheduleVisitForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  SubmitError,
  SubmitLoading,
  SubmitSuccessful,
} from "../../components/ScheduleVisitForm/SubmitStatuses";
import CustomerInfo from "./CustomerInfo";

const defaultValues = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  opportunity_number: "",
  address: "",
  HOA: false,
  notes: "",
  project_number: "",
};

const postNewAppointment = async (body) => {
  return { status: 200 };
  const { data } = await axios.post("#", body); //TODO: post here
  return data;
};

function ScheduleVisitDialog({ timeSlot, territory, setTimeSlot }) {
  const { time } = timeSlot;
  const day = dayjs(time);
  const form = useForm({ defaultValues });
  const [importedAddress, setImportedAddress] = useState("");

  const queryClient = useQueryClient();

  const {
    mutate,
    isLoading: postIsLoading,
    error: postError,
    isSuccess: postIsSuccess,
    reset: postReset,
  } = useMutation({
    mutationKey: ["post-new-appointment"],
    mutationFn: (body) => setTimeout(() => postNewAppointment(body), 1000), //TODO: remove setTimeout();
    onSuccess: () => queryClient.invalidateQueries("get-schedule"),
  });

  const { handleSubmit } = form;
  const handleClose = () => {
    setTimeSlot("");
    form.reset();
    postReset();
  };

  const onSubmit = (data) => {
    data.territory = territory;
    data.time = time;
    alert("Not hooked up");
    mutate(data);
  };

  const firstError = Object.values(form.formState.errors)[0];

  return (
    <FormProvider {...form}>
      <Dialog open={!!time} fullWidth>
        <DialogTitle>
          {day.format("h:mm - dddd MMM D YYYY ")}
          {timeSlot.slotsAvailable > 1 &&
            `(${timeSlot.slotsAvailable} Available)`}
          <Line />
          <Typography>{territory?.territory_name}</Typography>
        </DialogTitle>
        <DialogContent>
          {postIsLoading ? (
            <SubmitLoading />
          ) : postIsSuccess ? (
            <FetchStatusWrapper>
              <SubmitSuccessful />
              <Button onClick={handleClose}>Close</Button>
            </FetchStatusWrapper>
          ) : postError ? (
            <FetchStatusWrapper>
              <SubmitError />
              <Typography>
                Something went wrong, this appointment could not be made <br />
                {postError.message}
              </Typography>
              <Button onClick={() => postReset()} variant="contained">
                Back to Form
              </Button>
              <Button onClick={() => handleClose()} variant="outlined">
                Back to Calendar
              </Button>
            </FetchStatusWrapper>
          ) : (
            <>
              <SearchCustomersByName setImportedAddress={setImportedAddress} />
              <Line />
              <CustomerInfo importedAddress={importedAddress} />
              {firstError && <ErrorText>{firstError.message}</ErrorText>}
              <ButtonsWrapper>
                <Button onClick={handleClose} variant="outlined">
                  Cancel
                </Button>
                <Button onClick={handleSubmit(onSubmit)} variant="contained">
                  Submit
                </Button>
              </ButtonsWrapper>
            </>
          )}
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
}

export default ScheduleVisitDialog;

const Line = styled.div`
  min-height: 2px;
  background-color: #336699;
`;

const DialogContent = styled(DialogContentBase)`
  display: flex;
  flex-direction: column;
  gap: 2em;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-bottom: 4em;
`;
const ErrorText = styled(Typography)`
  text-align: center;
  font-size: 14px;
  color: #d32f2f;
`;

const FetchStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;
