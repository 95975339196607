import React, { useEffect, useMemo, useState } from "react";
import ImageSection from "./ImageSection";

const camelToText = (string) => {
  const result = string.replaceAll(/([A-Z])/g, " $1").replaceAll("_", " ");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

function ImageGallery({ data, imageSize, displayMode }) {
  console.log("DATA", data);

  return (
    <div>
      {Object.keys(data)
        .sort()
        .map((key) => (
          <ImageSection
            key={key}
            label={camelToText(key)}
            data={data[key]}
            imageSize={imageSize}
            displayMode={displayMode}
          />
        ))}
    </div>
  );
}

export default ImageGallery;
