import React from "react";
import { ReactComponent as FileIcon } from "../../../assets/fileIcon.svg";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";

function PDFPlaceholder() {
  return (
    <Wrapper>
      <FileIcon />
      <Typography>PDF</Typography>
    </Wrapper>
  );
}

export default PDFPlaceholder;

const Wrapper = styled.div`
  background-color: #336699;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  min-height: 100%;
  min-width: 100%;
  height: 100%;
  width: 100%;
  color: white;
  gap: 4px;

  p {
    font-weight: bold;
  }
`;
