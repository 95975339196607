import React from "react";
import BackgroundGradient from "../../components/BackgroundGradient";
import Card from "../../components/Card";

import {
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";

const MS_STATE_OPTIONS = [
  "AZ",
  "CA",
  "CT",
  "FL",
  "GA",
  "MA",
  "NJ",
  "NV",
  "NY",
  "NV",
  "PA",
  "RI",
  "TX",
];

const DUMMY_OFFICES = [
  {
    date: dayjs().format(),
    offices: [
      { name: "Office 1", openTimeSlots: 9 },
      { name: "Office 2", openTimeSlots: 0 },
      { name: "Office 3", openTimeSlots: 4 },
    ],
  },
  {
    date: dayjs().add(1, "day").format(),
    offices: [
      { name: "Office 1", openTimeSlots: 9 },
      { name: "Office 2", openTimeSlots: 0 },
      { name: "Office 3", openTimeSlots: 4 },
    ],
  },
  {
    date: dayjs().add(2, "day").format(),
    offices: [
      { name: "Office 1", openTimeSlots: 9 },
      { name: "Office 2", openTimeSlots: 0 },
      { name: "Office 3", openTimeSlots: 4 },
    ],
  },
];

const getOpenAppts = async (state) => {
  // const {data} =await axios.get()
  return DUMMY_OFFICES;
};

const AvailableAppointments = () => {
  const navigate = useNavigate();
  const { state } = useParams();

  const { data, fetchStatus } = useQuery({
    queryKey: ["get-available-appt-by-state", state],
    queryFn: () => getOpenAppts(state),
    enabled: !!state,
  });

  const handleChange = (event) => {
    navigate("/available-appointments/" + event.target?.value?.toUpperCase());
  };
  return (
    <BackgroundGradient>
      <Card>
        <Wrapper>
          <Typography variant="h5">Available EV Appointments</Typography>
          <FormControl>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              value={state?.toUpperCase() ?? ""}
              label="State"
              onChange={handleChange}
              placeholder="State"
            >
              {MS_STATE_OPTIONS?.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Select a state to view availability</FormHelperText>
          </FormControl>
          <Button variant={"contained"} onClick={() => navigate("/scheduler")}>
            Schedule an Appointment
          </Button>

          <Collapse in={!!data}>
            <OfficesGrid>
              {data?.map((dayData) => (
                <DayOverview data={dayData} />
              ))}
            </OfficesGrid>
          </Collapse>
        </Wrapper>
      </Card>
    </BackgroundGradient>
  );
};

export default AvailableAppointments;

const DayOverview = ({ data }) => {
  return (
    <>
      <OfficeHeader variant="h6">
        {dayjs(data.date).format("ddd MMM Do, YYYY")}{" "}
        {dayjs(data.date).isSame(dayjs(), "day") ? "(Today)" : ""}
      </OfficeHeader>

      {data?.offices.map((office) => {
        return <OfficeRow key={office.name} {...office} />;
      })}
    </>
  );
};
const OfficeRow = ({ name, openTimeSlots }) => {
  const style = {
    opacity: openTimeSlots > 0 ? 1 : 0.4,
  };
  return (
    <>
      <Typography sx={style}>{name}</Typography>
      <NumberWrapper
        style={{
          borderColor: openTimeSlots > 0 ? "#00ccff" : "grey",
          ...style,
        }}
      >
        <Typography
          variant="h6"
          style={{ color: openTimeSlots > 0 ? "#00ccff" : "grey", ...style }}
          fontWeight={"bold"}
        >
          {openTimeSlots}
        </Typography>
      </NumberWrapper>
      <Typography style={style}>Time slots available</Typography>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  min-width: 100%;
`;

const NumberWrapper = styled.div`
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  width: 32px;
  height: 32px;
  border: 1px solid #00ccff;
`;

const OfficesGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 1em;
  align-items: center;
`;

const OfficeHeader = styled(Typography)`
  border-bottom: 1px solid #336699;
  grid-column: 1 / span 3;
  width: "100%";
`;
