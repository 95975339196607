import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { getSpacingValues } from "./functions";

function DaySchedule({ openTimeSlots, timeRange, onSelectTime }) {
  const { minTime, nOfHours } = getSpacingValues(timeRange);
  return (
    <DayScheduleWrapper>
      {openTimeSlots.length > 0 ? (
        openTimeSlots.map((timeSlot) => {
          const { time } = timeSlot;
          const hour = dayjs(time).hour();
          const top = ((hour - minTime) / nOfHours) * 100 + "%";
          return (
            <ScheduleButton
              variant="contained"
              sx={{ top }}
              key={time}
              onClick={() => onSelectTime(timeSlot)}
            >
              {dayjs(time).format("h:00")} - Schedule Visit
            </ScheduleButton>
          );
        })
      ) : (
        <NoOpenTimeSlots>
          <Typography>
            No Unfilled <br /> Time Slots
          </Typography>
        </NoOpenTimeSlots>
      )}
    </DayScheduleWrapper>
  );
}

function DayLabel({ date }) {
  const day = dayjs(date, "YYYY-MM-DD");
  return (
    <DayLabelWrapper>
      {dayjs().isSame(day, "day") && (
        <Typography variant="caption">Today</Typography>
      )}
      <Typography sx={{ fontWeight: "bold" }}>{day.format("dddd")}</Typography>
      <Typography variant="subtitle2">{day.format("MMMM DD YYYY")}</Typography>
    </DayLabelWrapper>
  );
}

export { DayLabel, DaySchedule };

const DayLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 1em;
`;

const DayScheduleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  height: 100%;
`;

const ScheduleButton = styled(Button)`
  position: absolute;
  background-color: green;
`;

const NoOpenTimeSlots = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: grey;
  border: 1px solid lightgrey;
  border-radius: 16px;
`;
