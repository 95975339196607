import styled from "@emotion/styled";
import { Check, SearchOutlined } from "@mui/icons-material";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDebounce } from "../../util/Hooks";
import LoadingDots from "../LoadingDots";

const getCustomers = async (searchTerm) => {
  const { data } = await axios.get(
    "https://api-dev.momentumsolar.io/acuity/customers?name=" +
      searchTerm.toLowerCase()
  );
  return data;
};

function SearchCustomersByName({ setImportedAddress }) {
  const { setValue, watch, reset } = useFormContext();
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data: customers, isFetching } = useQuery({
    queryKey: ["search-customers", debouncedSearchTerm],
    queryFn: () => getCustomers(debouncedSearchTerm),
    enabled: !!debouncedSearchTerm,
  });

  const selectCustomer = (customer) => {
    reset();
    Object.keys(customer).forEach((key) => {
      if (key === "address") return setImportedAddress(customer.address);

      if (key === "full_name") {
        const nameArr = customer.full_name.split(" ");
        setValue("first_name", nameArr.shift());
        setValue("last_name", nameArr.join(" "));
      }

      setValue(key, customer[key]);
    });
  };

  const hasCustomer = !!(
    watch("first_name") ||
    watch("last_name") ||
    watch("phone") ||
    watch("email")
  );

  return (
    <Wrapper>
      <TextField
        variant="standard"
        placeholder="Johnny Solar"
        label="Search for a Customer"
        helperText="Search by customer's first or last names"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ width: "300px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlined />
            </InputAdornment>
          ),
        }}
      />
      <CustomersTable>
        {!searchTerm && (
          <MessageText>Search for a customer to begin</MessageText>
        )}
        {(!customers || customers.length === 0) &&
          searchTerm &&
          !isFetching && <MessageText>No customers found</MessageText>}
        {isFetching && (
          <>
            <LoadingDots />
            <MessageText>Searching customers...</MessageText>
          </>
        )}
        {!isFetching &&
          customers?.map((customer, i) => (
            <CustomerRow
              key={i}
              onClick={() => {
                selectCustomer(customer);
              }}
            >
              <Button>
                <Check />
              </Button>
              <Typography>{customer?.full_name}</Typography>
              <Typography variant="caption">{customer.address}</Typography>
            </CustomerRow>
          ))}
      </CustomersTable>
    </Wrapper>
  );
}

const CustomerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;
`;

export default SearchCustomersByName;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-top: 0.5em;
  gap: 0.5em;
`;

const CustomersTable = styled.div`
  height: 128px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  border: 1px lightgrey solid;
  border-radius: 5px;
`;

const MessageText = styled(Typography)`
  color: grey;
  font-size: 14px;
  text-align: center;
`;
