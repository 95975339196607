import styled from "@emotion/styled";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  CopyAllOutlined,
  Search,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ZoomableImage from "./ZoomableImage";
import isMOV from "../../../util/isMOV";
import isPDF from "../../../util/isPDF";
import VideoPlayer from "./VideoPlayer";
import PDFView from "./PDFView";

function GalleryDialog({ index, setIndex, images }) {
  const image = images[index] ?? images[0];
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);

  const handleNext = (e, direction) => {
    e.stopPropagation();
    setIndex((prev) => {
      let next = prev + direction;
      if (next < 0) next = images.length - 1;
      if (next >= images.length) next = 0;
      return next;
    });
  };

  const handleCopyToClipboard = (image) => {
    navigator.clipboard.writeText(image.img_url);
    setCopiedToClipboard(true);
    setTimeout(() => setCopiedToClipboard(false), 2000);
  };

  const handleKeyboardControls = (e) => {
    console.log(e);
    switch (e.key) {
      case "ArrowRight":
        handleNext(e, 1);
        break;
      case "ArrowLeft":
        handleNext(e, -1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const listener = window.addEventListener("keydown", handleKeyboardControls);

    return () => {
      window.removeEventListener("keydown", listener);
      window.removeEventListener("keydown", handleKeyboardControls);
    };
  }, []);

  return (
    <Dialog
      open={typeof index === "number" && !isNaN(index)}
      maxWidth="lg"
      fullWidth
      onClose={() => setIndex()}
      sx={{ zIndex: 10000, maxHeight: "100%" }}
      key={image.formLabel}
    >
      <DialogTitle sx={{ display: "flex", flexDirection: "row", gap: "1em" }}>
        <Typography variant="h6">
          {image.formLabel} - {image.inputLabel} - {index + 1}/{images.length}
        </Typography>
        <Stack direction={"row"} gap="0.5rem" style={{ marginLeft: "auto" }}>
          {!isMOV(image.img_url) && (
            <a href={image.img_url} target="_blank" rel="noreferrer">
              <Button variant="outlined" startIcon={<Search />}>
                Inspect
              </Button>
            </a>
          )}
          <Button
            variant="outlined"
            onClick={() => handleCopyToClipboard(image)}
            startIcon={<CopyAllOutlined />}
          >
            {copiedToClipboard ? "Copied" : "Copy URL"}
          </Button>
        </Stack>
        <Button onClick={() => setIndex()}>
          <Close />
        </Button>
      </DialogTitle>
      <ContentWrapper>
        {isMOV(image.img_url) ? (
          <VideoPlayer video={image} />
        ) : isPDF(image.img_url) ? (
          <PDFView image={image} />
        ) : (
          <ZoomableImage image={image} />
        )}
      </ContentWrapper>

      <DialogContent>
        <Typography>Uploaded By: {image.assessor_name}</Typography>
        <Typography>Appointment Date: {image.created_on}</Typography>
        <Typography>{image.notes}</Typography>
      </DialogContent>
      <NextImageButton style={{ left: 0 }} onClick={(e) => handleNext(e, -1)}>
        <ChevronLeft style={{ width: "72px", height: "72px" }} />
      </NextImageButton>
      <NextImageButton style={{ right: 0 }} onClick={(e) => handleNext(e, 1)}>
        <ChevronRight style={{ width: "72px", height: "72px" }} />
      </NextImageButton>
    </Dialog>
  );
}

export default GalleryDialog;

const NextImageButton = styled.button`
  position: absolute;
  top: calc(50% - 36px - 1em);
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  border: none;
  border-radius: 100px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ContentWrapper = styled.div`
  min-height: 400px;
`;
