import React from "react";
import { ReactComponent as VideoIcon } from "../../../assets/video-icon.svg";
import styled from "@emotion/styled";

function VideoPlaceholder({ src }) {
  return (
    <Wrapper>
      <VideoIcon style={{ color: "white" }} height="100%" width="100%" />
    </Wrapper>
  );
}

export default VideoPlaceholder;

const Wrapper = styled.div`
  background-color: #336699;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  padding: 15.5%;
  min-height: 70%;
  min-width: 70%;
  height: 70%;
  width: 70%;
`;
