import { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { useMemo } from "react";
import { useEffect } from "react";
import styled from "@emotion/styled";

export default function FormAddressAutocomplete({
  importedAddress,
  setPlaceId,
  name = "input_name",
  helperText = "",
  required,
  rules = {},
  defaultValue = "",
  ...props
}) {
  const { setValue: setFormValue, watch } = useFormContext();
  const _rules = {
    required: required
      ? (props?.label ?? "This field") + " is required"
      : false,
    ...rules,
  };

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const autocompleteService = useRef(null);

  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 500),
    []
  );

  //Keeps form state in sync
  useEffect(() => {
    setValue(watch(name));
  }, [watch(name)]);

  useEffect(() => {
    if (importedAddress) setInputValue(importedAddress);
  }, [importedAddress]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google)
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    if (!autocompleteService.current) return undefined;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) newOptions = [value];

        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Wrapper>
      {importedAddress && (
        <ImportedAddressWrapper>
          <Typography variant="h5">Verify Address:</Typography>
          <Typography variant="body">
            Please select a valid address from the dropdown below. If all
            options are incorrect, manually input the correct address.
          </Typography>
          <Typography variant="subtitle2" sx={{ margin: "8px 0px" }}>
            Imported Address: {importedAddress}
          </Typography>
        </ImportedAddressWrapper>
      )}
      <Controller
        name={name}
        rules={_rules}
        render={({
          form,
          formState: { errors },
          fieldState,
          ...controlProps
        }) => (
          <Autocomplete
            {...controlProps}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations found, please enter manually"
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              setPlaceId && setPlaceId(newValue?.place_id);
              setFormValue("address", newValue?.description ?? "");
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            rules={_rules}
            renderInput={(inputProps) => {
              return (
                <TextField
                  {...inputProps}
                  label="Add a location"
                  fullWidth
                  error={!!errors[name]}
                  helperText={errors[name] ? errors[name].message : helperText}
                  required
                />
              );
            }}
            renderOption={(props, option) => {
              const matches =
                option.structured_formatting.main_text_matched_substrings || [];

              const parts = parse(
                option.structured_formatting.main_text,
                matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
                ])
              );

              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item sx={{ display: "flex", width: 44 }}>
                      <LocationOnIcon sx={{ color: "text.secondary" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        width: "calc(100% - 44px)",
                        wordWrap: "break-word",
                      }}
                    >
                      {parts.map((part, index) => (
                        <Box
                          key={index}
                          component="span"
                          sx={{
                            fontWeight: part.highlight ? "bold" : "regular",
                          }}
                        >
                          {part.text}
                        </Box>
                      ))}
                      <Typography variant="body2" color="text.secondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }}
          />
        )}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ImportedAddressWrapper = styled.div`
  margin-bottom: 4px;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;
