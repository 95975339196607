import styled from "@emotion/styled";
import { Check, SearchOutlined, Toys } from "@mui/icons-material";
import {
  Button,
  Collapse,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import LoadingDots from "../LoadingDots";

const getCustomers = async (searchTerm) => {
  const { data } = await axios.get(
    "https://api-dev.momentumsolar.io/acuity/customers?ms=" +
      searchTerm.toUpperCase()
  );
  return data[0]; //TODO: will remove the [0]
};

function GetCustomerByProjectNumber({ setImportedAddress }) {
  const { setValue, reset } = useFormContext();
  const [searchTerm, setSearchTerm] = useState("");
  //   const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    data: customer,
    isLoading,
    fetchStatus,
    error,
    refetch: fetchCustomer,
  } = useQuery({
    queryKey: ["search-customers", searchTerm],
    queryFn: () => getCustomers(searchTerm),
    enabled: false,
  });

  const handleSelectCustomer = (newCustomer) => {
    reset(); //Clear old form data
    if (!newCustomer) return setImportedAddress();
    Object.keys(newCustomer).forEach((key) => {
      if (key === "address") return setImportedAddress(newCustomer.address);
      if (key === "full_name") {
        const nameArr = newCustomer.full_name.split(" ");
        setValue("first_name", nameArr.shift());
        setValue("last_name", nameArr.join(" "));
      }
      setValue(key, newCustomer[key]);
    });
  };
  const setProjectNumber = (e) => {
    e?.preventDefault();
    const newValue = e.target.value.toUpperCase();
    const formattedValue = newValue.replace(/[^MS\d]/g, "");
    setSearchTerm(formattedValue);
  };

  const handleSearch = () => {
    fetchCustomer();
  };

  useEffect(() => {
    handleSelectCustomer(customer);
  }, [customer]);

  return (
    <div>
      <Wrapper>
        <TextField
          variant="standard"
          placeholder="MS123456"
          label="Search By Project Number"
          helperText=""
          value={searchTerm}
          onChange={setProjectNumber}
          onKeyDown={(e) => e.key === "Enter" && handleSearch()}
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          disabled={!searchTerm}
          onClick={handleSearch}
        >
          Find Customer
        </Button>
      </Wrapper>
      {isLoading && fetchStatus === "fetching" && (
        <MessageText>Finding Customer...</MessageText>
      )}
      {error && (
        <MessageText style={{ color: "red" }}>
          {error?.message ?? error}
        </MessageText>
      )}
      <Collapse in={!!customer && fetchStatus !== "fetching"}>
        <FoundCustomerWrapper>
          <Typography>Customer Found:</Typography>

          <Typography fontSize={14}>{customer?.full_name}</Typography>
          <Typography fontSize={14}>{customer?.address}</Typography>
        </FoundCustomerWrapper>
      </Collapse>
    </div>
  );
}

export default GetCustomerByProjectNumber;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1em;

  align-items: flex-end;
`;

const MessageText = styled(Typography)`
  color: grey;
  font-size: 14px;
`;

const FoundCustomerWrapper = styled.div`
  border: 1px solid lightgrey;
  font-size: 12px;
  padding: 1em;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  align-items: center;
`;
